<template>
  <v-card
    :loading="loading"
    class="mx-auto"
    width="374"
    elevation="0"
  >
    <v-row justify="center" v-if="!loading">
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="teal"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Добавить номер в ЧС
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="text-center pt-4"><h3>Добавить в ЧС</h3></v-card-text>
          <v-card-subtitle class="text-center">
            Для внесения в черный список необходимо указать по крайней мере номер телефона
          </v-card-subtitle>
          <v-form v-model="valid" ref="form" style="margin-top: -30px">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="firstName"
                    color="teal lighten-1"
                    label="Имя"
                    prepend-icon="mdi-account"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="lastName"
                    color="teal lighten-1"
                    label="Фамилия"
                    prepend-icon="mdi-account"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    color="teal lighten-1"
                    label="Номер телефона"
                    @keyup="validatePhoneNumber"
                    required
                    persistent-hint
                    prepend-icon="mdi-phone"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="red"
              text
              @click="putInBlackHandler"
            >
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div class="mx-4" v-if="blackList.length">
      <v-text-field
        v-model="filter"
        prepend-icon="mdi-account-search"
        label="Поиск"
        color="teal lighten-1"
        class="pt-10"
      ></v-text-field>

      <v-card
        v-for="client in filtered(filter)"
        :key="client._id"
        class="pl10 my-2 mx-1 elevation-24 rounded"
        dark
      >
        <v-row justify="end">
          <v-menu
            min-width="70" offset-x left top origin="top"
            transition="slide-y-reverse-transition"
            content-class="elevation-0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small fab v-bind="attrs" v-on="on"
                     class="transparent elevation-0 mr-7 mt-5 teal--text"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-row justify="center" class="my-4">
              <v-btn @click="deleteFormBlackList(client)"
                     fab color="white" elevation="5" class="grey--text text--lighten-1">
                <v-icon>mdi-account-check</v-icon>
              </v-btn>
            </v-row>

            <v-row justify="center" class="my-4">
              <v-btn dark fab color="teal" elevation="5" :href="`tel:${client.phone}`">
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </v-row>

          </v-menu>
        </v-row>

        <div style="margin-top: -55px">
          <v-card-text style="margin-top: -20px">
            {{ client.firstName }}
            {{ client.lastName }}
            <br>
            {{ client.phone }}
          </v-card-text>
        </div>
      </v-card>
    </div>

    <v-card-text v-else class="text-center"><h3>ЧС пуст</h3></v-card-text>
  </v-card>
</template>

<script>
  import phoneFilter from "../../filters/phoneFilter"

  export default {
    name: "Clients",
    data: () => ({
      loading: false,
      blackList: [],
      dialog: false,
      filter: "",
      firstName: "",
      lastName: "",
      phone: "",
      phoneRules: [
        v => !!v.length || "Это обязательное поле",
        v => v.length === 12 || "Номер должен быть в формате +79*********"
      ],
      checkbox: false,
      checkboxRules: [
        c => !!c || "Для продолжения необходимо принять пользовательское соглашение"
      ],
      valid: true,
    }),

    methods: {
      async loadBlackList() {
        this.loading = true
        const json = await this.authFetch('/black/load-black-list', {})
        this.blackList = json.blackList
        this.loading = false
      },

      validatePhoneNumber() {
        this.phone = phoneFilter(this.phone)
      },

      filtered(str) {
        if (!this.blackList.length) return []
        if (!this.filter) return this.blackList

        const filtered = this.blackList
          .map(c => JSON.stringify({firstName: c.firstName, lastName: c.lastName, phone: c.phone}))
          .filter(j => j.toLowerCase().includes(str.toLowerCase()))
          .map(j => JSON.parse(j))

        return this.blackList.filter(c => filtered.map(f => f.phone).includes(c.phone))
      },

      async deleteFormBlackList(client) {
        const {phone} = client
        this.loading = true
        await this.authFetch('black/delete-from-black-list', {phone})
        this.loading = false
        await this.loadBlackList()
      },

      async putInBlackHandler() {
        this.$refs.form.validate()
        if (!this.valid) return

        if (!window.confirm("Вы уверены, что хотите внести номер в черный список?")) {
          return
        }

        const {firstName, lastName, phone} = this
        this.dialog = false
        this.loading = true
        await this.authFetch('/black/put-in-black-list', {firstName, lastName, phone})
        this.loading = false
        await this.loadBlackList()
      },
    },

    mounted() {
      this.loadBlackList()
    }

  }
</script>

<style scoped>

</style>
